<template>
  <div class="flex flex-col">
    <audio hidden="true" ref="audio">
      <source ref="audioSource" />
    </audio>

    <div>
      <div class="flex mb-3 items-center">
        <p class="text-mercury text-8 leading-140 font-normal">
          {{ playTime }}
        </p>

        <div class="w-full h-3px rounded-8 mx-2 cursor-pointer relative">
          <progress ref="progressBar" value="0" min="0"></progress>
          <input
            ref="seek"
            value="0"
            min="0"
            type="range"
            step="1"
            class="seek"
            @input="skipAhead"
            @mousemove="updateSeekTooltip"
          />
          <div ref="tooltip" class="seek-tooltip">00:00</div>
        </div>

        <p class="text-mercury text-8 leading-140 font-normal">
          {{ durationTime }}
        </p>
      </div>

      <div class="flex w-full justify-between">
        <div class="flex items-center w-full truncate">
          <button
            class="bg-white rounded-full w-8 h-8 flex items-center justify-center shrink-0"
            @click="play"
          >
            <i
              v-if="playing"
              class="icon-pause-2 text-grayscale-16 text-lg leading-18"
            />
            <i v-else class="icon-play2 text-grayscale-16 text-lg leading-18" />
          </button>
          <p
            v-if="title"
            class="truncate text-grayscale-96 font-bold text-sm leading-160 pl-3"
            :class="{ 'pr-3': showDownload }"
          >
            {{ title }}
          </p>
        </div>
        <button
          v-if="showRemove"
          class="bg-grayscale-96 rounded-full w-8 h-8 flex items-center justify-center shrink-0"
          @click="remove"
        >
          <i class="icon-close text-grayscale-16 text-base leading-4" />
        </button>
        <button
          v-if="showDownload"
          :disabled="downloading"
          class="bg-grayscale-96 rounded-full w-8 h-8 flex items-center justify-center shrink-0"
          @click="download"
        >
          <img
            v-if="downloading"
            src="@/assets/icons/spinner.svg"
            alt="spinner"
            class="animate-spin h-4 w-4"
          />
          <i
            v-else
            class="icon-download text-grayscale-16 text-base leading-4"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { parseMediaURL } from "~/utils/aws";
import axios, { AxiosResponse } from "axios";

const props = defineProps({
  file: {
    type: String,
    required: true,
  },
  duration: {
    type: Number,
  },
  showRemove: {
    type: Boolean,
  },
  showDownload: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
  },
});
const emit = defineEmits(["onFileRemove", "play", "pause"]);

const playing = ref(false);
const audioSource = ref<HTMLSourceElement>();
const audio = ref<HTMLAudioElement>();
const playTime = ref("00:00");
const durationTime = ref("00:00");

const seek = ref<HTMLInputElement>();
const progressBar = ref<any>();
const tooltip = ref<HTMLDivElement>();

onMounted(() => {
  initAudio();
});

const initAudio = () => {
  if (audio.value) {
    audio.value.src = parseMediaURL(props.file);
    audio.value.load();
    audio.value.addEventListener("loadedmetadata", () => {
      durationTime.value = formatTime(audio.value!.duration);
      seek.value!.setAttribute("max", audio.value!.duration.toString());
      progressBar.value!.setAttribute("max", audio.value!.duration.toString());
    });
    audio.value.addEventListener("timeupdate", () => {
      playTime.value = formatTime(audio.value!.currentTime);
      updateProgress();
    });
    audio.value.addEventListener("ended", () => {
      audio.value!.currentTime = 0;
      updateProgress();
      playing.value = false;
    });
    audio.value.addEventListener("play", () => {
      playing.value = true;
    });
    audio.value.addEventListener("pause", () => {
      playing.value = false;
    });
  }
};

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const secondsLeft = Math.floor(seconds % 60);

  const minutesString = minutes < 10 ? "0" + minutes : minutes;
  const secondsString = secondsLeft < 10 ? "0" + secondsLeft : secondsLeft;

  return `${minutesString}:${secondsString}`;
};

const updateProgress = () => {
  const currentTime = audio.value!.currentTime;
  if (seek.value) {
    seek.value.value = Math.floor(currentTime).toString();
  }
  progressBar.value.value = Math.floor(currentTime);
};

const play = () => {
  playing.value = !playing.value;

  if (playing.value) {
    audio.value?.play();
    emit("play");
  } else {
    audio.value?.pause();
    emit("pause");
  }
};

const remove = () => {
  emit("onFileRemove");
};

const downloading = ref(false);

const saveFile = (data: Blob, filename: string) => {
  // Create a link element to download the file.
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);

  // Append the link to the DOM and click it to trigger the download.
  document.body.appendChild(link);
  link.click();

  // Clean up the URL object after download.
  window.URL.revokeObjectURL(url);
};

const downloadFile = async (url: string, filename?: string) => {
  try {
    downloading.value = true;
    // Replace 'YOUR_FILE_URL' with the actual URL to download the file.
    const response: AxiosResponse = await axios.get(url, {
      responseType: "blob", // To receive the file as a binary blob
    });

    const contentType = response.headers["content-type"];

    // Process the downloaded file (e.g., save it or open it).
    saveFile(
      response.data,
      (filename || "file") + "." + contentType.split("/")[1],
    );
  } catch (error) {
    downloading.value = false;
    console.error("Error downloading file:", error);
  } finally {
    downloading.value = false;
  }
};

const download = () => {
  downloadFile(parseMediaURL(props.file), props.title);
};

const updateSeekTooltip = (event: any) => {
  const skipTo = Math.round(
    (event.offsetX / event.target.clientWidth) *
      parseInt(event.target.getAttribute("max"), 10),
  );
  seek.value!.setAttribute("data-seek", skipTo.toString());
  if (tooltip.value?.textContent) {
    tooltip.value.textContent = formatTime(skipTo);
  }
  const rect = event.target.getBoundingClientRect();
  tooltip.value!.style.left = `${event.pageX - rect.left + 50}px`;
};

const skipAhead = (event: any) => {
  const skipTo = event.target.dataset.seek
    ? event.target.dataset.seek
    : event.target.value;

  audio.value!.currentTime = parseInt(skipTo, 10);
  updateProgress();
};
</script>

<style scoped>
progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 8px;
  width: 100%;
  height: 3px;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

progress::-webkit-progress-bar {
  background-color: rgba(234, 234, 234, 0.2);
  border-radius: 8px;
}

progress::-webkit-progress-value {
  background: #f5f5f5;
  border-radius: 8px;
}

progress::-moz-progress-bar {
  background: #f5f5f5;
}

.seek {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  cursor: pointer;
  margin: 0;
  height: 3px;
  opacity: 0;
}

.seek:hover + .seek-tooltip {
  display: block;
}

.seek-tooltip {
  display: none;
  position: absolute;
  top: -20px;
  pointer-events: none;
  margin-left: -62px;
  font-size: 8px;
  padding: 2px;
  content: attr(data-title);
  font-weight: bold;
  color: #f5f5f5;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 1px;
}

.seek::-webkit-slider-runnable-track {
  width: 100%;
  cursor: pointer;
  border-radius: 1.3px;
  -webkit-appearance: none;
  transition: all 0.4s ease;
}

.seek::-webkit-slider-thumb {
  height: 16px;
  width: 16px;
  border-radius: 16px;
  background: transparent;
  cursor: pointer;
  -webkit-appearance: none;
  margin-left: -1px;
}

.seek:focus::-webkit-slider-runnable-track {
  background: transparent;
}

.seek::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  border: 1px solid transparent;
  background: transparent;
  border-radius: 1.3px;
}

.seek::-moz-range-thumb {
  background: transparent;
  cursor: pointer;
}

.seek:focus::-moz-range-track {
  outline: none;
}
</style>
